<script>
  import { authModule } from "@/stores/auth.js";
  import { generalModule } from "@/stores/general.js";
  import { mapStores } from 'pinia'
  import { Offcanvas } from 'bootstrap';
  import Headhesive from 'headhesive';
  import { poly } from '@/assets/js/vendor/smoothscroll.js';

  export default {
    name: "Cranium",

    data() {
      return {
        init: false
      }
    },
    mounted() {
      this.initSmooth();
      this.stickyHead();
      this.initOffCanvas();

      window.onscroll = () => {
        let currentScrollPos = window.pageYOffset;

        if (currentScrollPos > 388 && !this.init) {
          this.initSmooth()
          this.init = true
        }
      }
    },
    computed: {
      ...mapStores(authModule),
      ...mapStores(generalModule)
    },
    methods: {
      initSmooth() {
        poly.polyfill();
        const links = document.querySelectorAll(".scroll");
        for (const link of links) {
          link.addEventListener("click", clickHandler);
        }
        function clickHandler(e) {
          e.preventDefault();
          this.blur();
          const href = this.getAttribute("href");
          const offsetTop = document.querySelector(href).offsetTop;

          scroll({
            top: offsetTop,
            behavior: "smooth"
          });
        }
      },
      stickyHead() {
        let navbar = document.querySelector(".navbar");
        if (navbar == null) return;
        let options = {
          offset: 350,
          offsetSide: 'top',
          classes: {
            clone: 'navbar-clone fixed',
            stick: 'navbar-stick',
            unstick: 'navbar-unstick',
          },
          onStick: function() {
            let navbarClonedClass = this.clonedElem.classList;
            if (navbarClonedClass.contains('transparent') && navbarClonedClass.contains('navbar-dark')) {
              this.clonedElem.className = this.clonedElem.className.replace("navbar-dark","navbar-light");
            }
          }
        };
        new Headhesive('.navbar', options);
      },
      initOffCanvas() {
        var navbar = document.querySelector(".navbar");
        if (navbar == null) return;
        const navOffCanvasBtn = document.querySelectorAll(".offcanvas-nav-btn");
        const navOffCanvas = document.querySelector('.navbar:not(.navbar-clone) .offcanvas-nav');
        const bsOffCanvas = new Offcanvas(navOffCanvas, {scroll: true});
        const scrollLink = document.querySelectorAll('.onepage .navbar li a.scroll');
        const searchOffcanvas = document.getElementById('offcanvas-search');
        navOffCanvasBtn.forEach(e => {
          e.addEventListener('click', event => {
            bsOffCanvas.show();
          })
        });
        scrollLink.forEach(e => {
          e.addEventListener('click', event => {
            bsOffCanvas.hide();
          })
        });
        if(searchOffcanvas != null) {
          searchOffcanvas.addEventListener('shown.bs.offcanvas', function () {
            document.getElementById("search-form").focus();
          });
        }
      }
    }
  }
</script>

<template>
  <header class="wrapper bg-soft-primary" id="hero">
    <nav class="navbar navbar-expand-lg center-nav transparent navbar-light">
      <div class="container flex-lg-row flex-nowrap align-items-center">
        <div class="navbar-brand w-100">
          <a href="/">
            <img src="/farin-to-yaad.png" srcset="" alt="" width="200" />
          </a>
        </div>
        <div class="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
          <div class="offcanvas-header d-lg-none">
            <h3 class="text-white fs-30 mb-0">
              <a href="/">
                <img src="/farin-to-yaad.png" srcset="" alt="" width="200" />
              </a>
            </h3>
            <button type="button" class="btn-close btn-close-white" style="margin-left: 6rem;" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body ms-lg-auto d-flex flex-column h-100">
            <ul v-if="!authStore.isLoggedIn" class="navbar-nav">
              <li class="nav-item"><a class="nav-link scroll" href="#hero">Home</a></li>
              <li class="nav-item"><a class="nav-link scroll" href="#about">About</a></li>
              <li class="nav-item"><a class="nav-link scroll" href="#works">How It Works</a></li>
              <li class="nav-item"><a class="nav-link scroll" href="#rates">Rates</a></li>
              <li class="nav-item"><a class="nav-link scroll" href="#contact">Contact Us</a></li>
            </ul>
            <ul v-else class="navbar-nav">
              <li class="nav-item"><router-link class="nav-link" to="/dashboard">Dashboard</router-link></li>
              <li class="nav-item"><router-link class="nav-link" to="/packages">Packages</router-link></li>
              <li class="nav-item dropdown d-lg-none">
                <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">{{ authStore.isLoggedIn ? authStore.userObj.firstname + ' ' + authStore.userObj.lastname : 'No User' }}</a>
                <ul class="dropdown-menu">
                  <router-link class="dropdown-item" to="/account" data-bs-toggle="dropdown"><i class="uil uil-user"></i> Account</router-link>
                  <li class="nav-item"><a class="dropdown-item" href="javascript:void(0)" @click="authStore.logOut"><i class="uil uil-sign-out-alt"></i> Logout</a></li>
                </ul>
              </li>
            </ul>
            <!-- /.navbar-nav -->
            <div class="offcanvas-footer d-lg-none">
              <div>
                <a :href="`mailto:${generalStore.communication.company_email}`" class="link-inverse">{{ generalStore.communication.company_email }}</a>
                <br /> {{ generalStore.communication.company_phone }} <br />
                <nav class="nav social social-white mt-4" v-if="generalStore.communication">
                  <a v-if="generalStore.communication.social_media && generalStore.communication.social_media.x" target="_blank" :href="generalStore.communication.social_media.x"><i class="uil uil-twitter"></i></a>
                  <a v-if="generalStore.communication.social_media && generalStore.communication.social_media.facebook" target="_blank" :href="generalStore.communication.social_media.facebook"><i class="uil uil-facebook-f"></i></a>
                  <a v-if="generalStore.communication.social_media && generalStore.communication.social_media.instagram" target="_blank" :href="generalStore.communication.social_media.instagram"><i class="uil uil-instagram"></i></a>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="navbar-other w-100 d-flex ms-auto">
          <ul class="navbar-nav flex-row align-items-center ms-auto">
            <li v-if="!authStore.isLoggedIn" class="nav-item">
              <router-link to="/login" class="btn btn-sm btn-gradient gradient-7 rounded-xl">LOGIN</router-link>
            </li>
            <li v-else class="nav-item dropdown d-none d-lg-block">
              <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">{{ authStore.isLoggedIn ? authStore.userObj.firstname + ' ' + authStore.userObj.lastname : 'No User' }}</a>
              <ul class="dropdown-menu">
                <li class="nav-item"><a class="dropdown-item" href="/account"><i class="uil uil-user"></i> Account</a></li>
                <li class="nav-item"><a class="dropdown-item" href="javascript:void(0)" @click="authStore.logOut"><i class="uil uil-sign-out-alt"></i> Logout</a></li>
              </ul>
            </li>
            <li class="nav-item d-lg-none">
              <button class="hamburger offcanvas-nav-btn"><span></span></button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<style scoped>

</style>