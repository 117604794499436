<script>
import { mapStores } from "pinia";
import { generalModule } from "@/stores/general.js";

export default {
  name: "feet",
  computed: {
    ...mapStores(generalModule)
  },
}
</script>

<template>
  <footer class="bg-white">
    <div class="container pt-8 pt-md-5 pb-7">
      <div class="row gx-lg-0 gy-6" v-if="$route.path === '/'">
        <div class="col-lg-4 mt-0">
          <div class="widget">
            <img class="mb-4" src="/farin-to-yaad.png" srcset="/farin-to-yaad.png" alt="" width="200" />
            <p class="lead mb-0">Join thousands of satisfied customers who trust us for fast, reliable shipping. Use our services today and take your business to new heights.</p>
          </div>
          <!-- /.widget -->
        </div>
        <!-- /column -->
        <div class="col-lg-3 offset-lg-2">
          <div class="widget">
            <div class="d-flex flex-row">
              <div>
                <div class="icon text-primary fs-28 me-4 mt-n1"> <i class="uil uil-phone-volume"></i> </div>
              </div>
              <div>
                <h5 class="mb-1">Phone</h5>
                <p class="mb-0">{{ generalStore.communication.company_phone || 'N/A' }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="widget">
            <div class="d-flex flex-row">
              <div>
                <div class="icon text-primary fs-28 me-4 mt-n1"> <i class="uil uil-location-pin-alt"></i> </div>
              </div>
              <div class="align-self-start justify-content-start">
                <h5 class="mb-1">Address</h5>
                <address v-if="generalStore.local_address">{{ generalStore.local_address.address }}, {{ generalStore.local_address.city }}, {{ generalStore.local_address.parish }}</address>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-10 mt-md-10 mb-7" />
      <div class="d-md-flex align-items-center justify-content-between">
        <p class="mb-2 mb-lg-0">© {{ new Date().getUTCFullYear() }} FarinToYaad Ltd. All rights reserved.</p>
        <nav class="nav social social-muted mb-0 text-md-end" v-if="generalStore.communication">
          <a v-if="generalStore.communication.social_media && generalStore.communication.social_media.x" target="_blank" :href="generalStore.communication.social_media.x"><i class="uil uil-twitter"></i></a>
          <a v-if="generalStore.communication.social_media && generalStore.communication.social_media.facebook" target="_blank" :href="generalStore.communication.social_media.facebook"><i class="uil uil-facebook-f"></i></a>
          <a v-if="generalStore.communication.social_media && generalStore.communication.social_media.instagram" target="_blank" :href="generalStore.communication.social_media.instagram"><i class="uil uil-instagram"></i></a>
        </nav>
      </div>
    </div>
  </footer>
</template>

<style scoped>

</style>