<script setup>
  import { RouterView } from 'vue-router'
  import layouts from "./layouts.js";
  import { shallowRef, provide, inject } from "vue";
  import router from "./router/index.js";
  import { generalModule } from "@/stores/general.js";

  const generalStore = generalModule()

  const axios = inject('axios')
  const layout = shallowRef('div')
  router.afterEach(async (to) => {
    layout.value = layouts[to.meta.layout] || 'div'
    let { data } = await axios.get('/client')
    generalStore.setSettings(data)
  })

  provide('app:layout', layout)

  // Add class to body when route is on home
  router.afterEach((to) => {
    if (to.path === '/') {
      document.body.classList.add('onepage')
    } else {
      document.body.classList.remove('onepage')
    }
  })
</script>

<template>
  <component :is="layout || 'div'">

<!--  Add Error component  -->
    <RouterView />
  </component>
</template>

<style scoped>

</style>
